html,body {
  min-width: 320px;
  font-family: 'Roboto', sans-serif;
  background-color: #444;
  color: #fff;
  /*min-height: 100vh;*/
}
.container{
  background-color: #444;
  color: #fff;
  margin-top: 4rem;
  min-height: 50vh;
}
footer {
    z-index: 1;
    position: relative;
    overflow: hidden;
    background-color: #333;
    color: #fff;
}
footer .footer-top {
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
footer .footer-bottom {
    margin: 0 40px;
    height: 80px;
    font-size: 12px;
}
.center-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
footer .footer-text {
    margin: auto;
    max-width: 1080px;
    line-height: 1.43;
    font-size: 14px;
    text-align: center;
}
footer .footer-top A {
    text-decoration: underline;
}
footer .footer-bottom-left {
    justify-content: flex-start;
    width: 50%;
}
footer .footer-main-logo {
    margin-right: 20px;
    height: 40px;
}
footer .copyright-text {
    display: inline-block;
    margin-right: 20px;
}
footer .copyright-link-container {
    font-weight: 500;
}
footer .copyright-link-item {
    margin-right: 15px;
}
footer .footer-bottom-right {
    justify-content: flex-end;
    width: 50%;
}
footer .label-responsibility {
    margin-right: 20px;
}
footer .links-social {
    float: right;
    /* display: none; */
}
footer [class*='links-social-item-']:first-child {
    margin-right: 0;
}
footer [class*='links-social-item-'] {
    float: right;
    margin-right: 10px;
}
footer [class*='social-icon-'] {
    float: left;
    margin-right: 10px;
    border: 1px solid rgba(143, 153, 175, 0.25);
    border-radius: 50%;
    width: 38px;
    height: 38px;
    transition: background-color 0.5s cubic-bezier(0, 0, 0, 0.99);
}
footer [class*='social-icon-'] [class*='icon-'] {
    height: 16px;
    font-size: initial;
    color: #ffffff;
}
footer a {
    text-decoration: none;
    outline: none;
    color: inherit;
    border-bottom: 0 !important;
}
[class*='icon-'] {
    display: inline-block;
}
footer .social-label {
    display: inline-block;
    font-weight: 500;
    color: #8f99af;
}
footer a:hover {
    color: #f5a623;
}
.active i,.active span {
    color: #f5a623 !important;
}
.menu-item {
  cursor: pointer;
  display: inline-block;
  padding: 0 20px;
  opacity: 0.75;
  color: #ffffff;
}
.menu-label {
  color: #ffffff;
  font-size: 1em;
  letter-spacing: .1em;
  text-transform: capitalize;
}
header .menu-label {
  display: inline-block;
  line-height: 2.4;
  color: #ffffff;
  letter-spacing: 0.3px;
  font-weight: 500;
}
.active {
  border-bottom: 4px solid;
  border-image: linear-gradient(to right, #f5a623, #f5a623);
  border-image-slice: 1;
  opacity: 1 !important;
  color: #f5a623;
}
header .menu-item {
      cursor: pointer;
      display: inline-block;
      padding: 0 20px;
      opacity: 0.75;
      color: #ffffff;
}
.main-menu{
  justify-content: center;
}
@media (max-width: 800px){
  FOOTER .footer-bottom {
    flex-direction: column-reverse;
    height: 140px;
  }
  FOOTER .footer-main-logo {
    display: none;
  }
  FOOTER .footer-bottom-left,
  FOOTER .footer-bottom-right {
    justify-content: space-around;
    flex-direction: column-reverse;
    width: unset;
    height: 50px;
  }
  FOOTER .footer-bottom-left {
    margin-bottom: 20px;
    height: 60px;
  }
  FOOTER .footer-bottom-right {
    justify-content: center;
    margin-top: 10px;
  }

  .break-large,
  .break-medium,
  .break-small {
    display: none !important;
  }

  .show-small {
    display: block !important;
  }

  SPAN.show-small {
    display: inline !important;
  }
  header .menu-item {
    width: auto;
    height: 30px;
  }
  header .menu-item {
    margin-bottom: 3px;
    padding: 0;
    height: 45px;
  }
  header .main-menu .menu-item .menu-label {
      display: none;
    }
    header .menu-label {
      width: 100%;
      line-height: 1.43;
      text-align: center;
      font-size: 14px;
    }
  header .header-top {
    /*border-bottom: 1px solid #f5a623;*/
    padding: 10px;
  }
}
@media (max-width: 1200px){
  header .header-top {
    padding: 20px;
  }
}
    header .header-top {
      padding: 20px 40px 10px 40px;
      text-align: center;
    }
header .header-blue-container {
  background-color: #333;
}
header .menu-item:hover {
  opacity: 1;
}

.main-content .matches-item {
  margin: 0 10px 20px 10px;
  height: 140px;
  border-radius: 8px;
  background-color: #333;
  transition: all 1s ease;
  /*box-shadow: 0 4px 16px 0 rgba(94, 115, 129, 0.15);*/
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}

.main-content .matches-info {
  position: relative;
  float: left;
  padding: 30px 10px 22px 10px;
  text-align: center;
}
.main-content .matches-info {
  padding: 36px 10px 7px 10px;
  width: calc(100%);
  font-size: 0;
}
.home-team, .main-content .away-team {
  width: calc(50% - 80px);
}
.main-content .home-team {
  float: left;
  text-align: center;
  font-size: 0;
}
.main-content .away-team {
  float: right;
  text-align: center;
  font-size: 0;
}
.main-content .home-team-logo-link, .main-content .away-team-logo-link {
  height: 50px;
}
.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content .home-team-logo, .main-content .away-team-logo {
  max-width: 50px;
  max-height: 50px;
}
.main-content .home-team-logo, .main-content .away-team-logo {
  background-position: center;
}
a img {
  border: none;
}

.main-content .matches-info .home-team-name, .main-content .matches-info .away-team-name {
  margin-top: 6px;
  height: 50px;
}
  .main-content .home-team-name, .main-content .away-team-name {
    max-height: 34px;
  }
  .main-content .home-team-name, .main-content .away-team-name {
    line-height: 1.24;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    font-weight: 500;
    color: #f5a623;
  }
.main-content .result-container {
  display: inline-block;
}
.main-content .match-finished .result-container .result {
  color: #b5b5b5;
}
.soccer .main-content .result {
  color: #ff553e;
}
.main-content .result {
  color: #ff553e;
}

.main-content .match-finished .status-container {
  color: #b5b5b5;
}
  .main-content .status-container {
    margin-top: 16px;
    max-width: 160px;
  }
  .main-content .status-container {
    line-height: 1.25;
    font-size: 12px;
    font-weight: 500;
  }
  .responsive-text {
    /*overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;*/
  }
.main-content .result-home, .main-content .result-away {
  font-size: 30px;
  font-weight: 500;
  transition: color 10s cubic-bezier(0, 0, 0, 0.99);
}

.main-content .result-dash {
  font-size: 25px;
}
  .main-content .result-home, .main-content .result-away {
    font-size: 30px;
  }
  .main-content .result-dash,.main-content .start-time {
    color: #b5b5b5;
  }
.main-content .info-container {
  top: 10px;
  right: 5px;
  bottom: unset;
  left: 5px;
  text-align: center;
  position: absolute;
  line-height: 1.2;
  font-weight: 500;
  font-size: 12px;
  color: #b5b5b5;
}
.main-content .info-content {
  display: inline-block;
  width: calc(100% - 25px);
  height: 17px;
}
.main-content .info-container .start-time, .main-content .info-container .league-name {
  position: relative;
  top: 3px;
}
.main-content .matches-info .info-dot {
  top: unset;
}
.main-content .info-dot {
  display: inline-block;
  position: relative;
  top: -3px;
  margin: 0 2px;
  border-radius: 100%;
  width: 3px;
  height: 3px;
  background: #b5b5b5;
}
.main-content .info-container .start-time, .main-content .info-container .league-name {
  position: relative;
  top: 3px;
}

@media (max-width: 800px){
header .menu-item [class*='icon-'] {
  top: unset;
  width: 100%;
  height: 24px;
  text-align: center;
  font-size: 24px;
}}
  header .menu-item [class*='icon-'] {
    position: relative;
    margin-right: 6px;
    color: #ffffff;
    margin-left: 3px;
  }
  .main-content .missing-live-videos {
      padding: 0 20px;
      height: 125px;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 40px;
      border: 2px dashed #f5a623;
      border-radius: 8px;
      background-color: #333;
      margin: 0 10px 20px 10px;
  }
  .loading {
      padding: 0 20px;
      height: 125px;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 40px;
      border-radius: 8px;
      background-color: #333;
      margin: 0 10px 20px 10px;
  }
  .main-content .empty-event-label {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 500;
      opacity: 0.75;
      text-align: center;
      width: 100%
  }
  header {
    z-index: 1;
    position: sticky;
    top: 0;
    box-shadow: 0px 20px 20px -15px rgba(195, 137, 36, 0.15);
}
.container-brand {
    border-bottom: 2px solid #c38924;
    margin: 0 auto;
    width: 250px;
    padding-bottom: 1rem;
}
.seo {
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
    border-radius: 8px;
    background-color: #333;
    margin: 0 10px 50px 10px;
}
.seo-body {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    opacity: 0.75;
    color:#fff;
    width: 100%
}
p.has-text-align-center {
    text-align: center;
    margin-bottom: 2rem !important;
}
.seo p{
  margin-bottom: 0.75rem;
}
.seo p:before {
    content: "";
    white-space: pre;
}
.seo h3 {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 500;
    text-transform: capitalize;
}

.seo h4 {
    margin: 1rem 0;
    font-weight: 500
}
@media (max-width:400px){.main-content .away-team-logo, .main-content .home-team-logo{max-width: 35px;max-height: 35px;}content .home-team-name {font-size: 13px;}}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 4px 16px 0 rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	background-color: #555;
}

body::-webkit-scrollbar
{
	width: 8px;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 4px 16px 0 rgba(0, 0, 0, 0.15);
	background-color: #333;
}
body{overflow-y:auto;}
.social-links img {
    width: 55px;
    height: 55px;
    padding: 10px;
    background: #333333;
}
.sidebar {
    text-align: center;
}
.social-links {
    margin-bottom: 30px;
    width: 100%;
}
.social-links .social-tooltip {
    display: block;
    font-size: 20px;
    margin: 0 10px;
    color: rgba(255,255,255,.7);
}
.social-links .social-tooltip {
    color: #333;
    font-size: 24px;
    display: inline;
    position: relative;
    z-index: 98;
}
.social-links li a {
    display: block!important;
    position: relative;
    text-align: center;
}
.social-links a img {
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;
}
.social-links img {
    -webkit-border-radius: 30px;
    border-radius: 30px;
    max-width: 100%;
    height: auto;
    box-shadow: 0 10px 10px rgba(0,0,0,.1), 0 1px 1px rgba(0,0,0,.15);
}
.social-links li {
    display: inline-block;
}
.social-links .social-tooltip img:hover {
    background: rgba(0,0,0,.7);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), inset 0 -1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(0,0,0,.1);
}
.sublink {
  text-align: right;
  margin: 0 0px 10px -10px;
  width: 100%;
}

a.lnk {
  width: 55px;
  height: 55px;
  padding: 10px;
  background: #333333;
  border-radius: 20px;
  font-size: 0.85em;
  text-transform: capitalize;
  color: #fff;
  letter-spacing: 0.3px;
  transition: opacity .2s ease;
  box-shadow: 0 10px 10px rgba(0,0,0,.1), 0 1px 1px rgba(0,0,0,.15);
}

a.lnk:hover {
  background: rgba(0,0,0,.7);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), inset 0 -1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(0,0,0,.1);
}